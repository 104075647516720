export default {
  DEVICE_TYPE: {
    SMART_METER: 'smart_meter',
    COLLECTOR: 'collector',
    INVERTER: 'inverter',
    METER: 'meter',
    TYPE_RF_SUB_DEVICE: 'type_rf_sub_device',
    GAS_METER: 'gas_meter',
    PRICES: 'prices',
    BATTERY_CONTROL: 'battery_control',
  },
  DEVICE_SUB_TYPE: {
    KWH_METER: 'kwh_meter',
    SMART_METER: 'smart_meter',
    HEAT_PUMP: 'heat_pump',
    AIRCO: 'airco',
    INVERTER: 'inverter',
    EV_CHARGER: 'ev_charger',
    BATTERY: 'battery',
    BATTERY_AND_INVERTER: 'battery_and_inverter',
    PV_INVERTER: 'inverter_pv',
  },
  NO_DATA: 'no_data',
  USER_ROLES: {
    BLIQ_ADMIN: 'bliq_admin',
    INSTALLER_MAIN: 'installer_main',
    INSTALLER_SUB: 'installer_sub',
    INSTALLER_EMPLOYEE: 'installer_employee',
    USER: 'user',
    MECHANIC: 'mechanic',
  },
  MEAS_KEYS: {
    POWER: 'power',
    IMPORT: 'import',
    BATTERY_POWER: 'battery_power',
    PV_POWER: 'pv_power',
    EXPORT: 'export',
    PRICES: 'prices',
    STATE_OF_CHARGE: 'battery_soc',
  },
  SCHEMATIC_ARROWS: {
    COLORS: {
      ACTIVE: '#74CF9E',
      INACTIVE: '#CEE0EB',
    },
    PLUGS: {
      ARROW: 'arrow2',
      NO_ARROW: 'behind',
    },
  },
  DEFAULT_PEAK_LOAD: 0.1,
  SELF_TEST_STATUS: {
    UNKNOWN: 'UNKNOWN',
    PENDING: 'PENDING',
    SUCCESSFUL: 'SUCCESSFUL',
    FAILED: 'FAILED',
    NOT_READY: 'NOT_READY',
  },
  FEATURES: {
    CUSTOMER: {
      SHOW_APP_STATUS: 'show_app_status',
      HOUSE_NUMBER_EXT_MAX_LENGTH: 30,
      STREET_MAX_LENGTH: 60,
      NL_HOUSE_NUMBER_MIN_VAL: 1,
      NL_HOUSE_NUMBER_MAX_VAL: 99999,
      BE_HOUSE_NUMBER_MIN_VAL: 0,
      BE_HOUSE_NUMBER_MAX_VAL: 99999999,
    },
  },
  COUNTRIES_CODES: {
    NETHERLANDS: 'NL',
    BELGIUM: 'BE',
  },
};
