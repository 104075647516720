import Base from '@/api/Base';
import GetBatteryInfo from '@/api/dtos/GetBatteryInfo';
import Store from '../store';

export default class BatteryInfo extends Base {
  constructor() {
    super(process.env.VUE_APP_RESIDENCE_API_ROOT_URL);
    this.instance.interceptors.request.use((config) => {
      const token = Store.getters['auth/getRoleAccessToken'];
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  async GetBatteryInfo(deviceId: string): Promise<GetBatteryInfo> {
    const { data } = await this.instance.get<GetBatteryInfo>(`/v1/battery-info/${deviceId}`);

    return data;
  }
}
