import './class-component-hooks';
import Vue from 'vue';
import App from './App.vue';
import errorHandler from './helpers/errorHandler';
import i18n from './helpers/i18n';
import router from './router';
import store from './store';
import './filters/filters';
import './assets/tailwind.css';

// Error handlers
window.addEventListener('error', (e: ErrorEvent) => {
  e.preventDefault();
  errorHandler('Uncaught error', e.error);
});
window.addEventListener('unhandledrejection', (e: PromiseRejectionEvent) => {
  e.preventDefault();
  errorHandler('Uncaught rejection', e.reason);
});

Vue.config.errorHandler = (e: Error) => {
  errorHandler('Vue error', e);
};

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
