import Identity from './Identity';
import Residence from './Residence';
import Analytics from './Analytics';
import Meas from './Meas';
import Battery from './Battery';
import Consumption from './Consumption';
import BatteryInfo from './BatteryInfo';

export const identity: Identity = new Identity();
export const residence: Residence = new Residence();
export const analytics: Analytics = new Analytics();
export const meas: Meas = new Meas();
export const battery: Battery = new Battery();
export const consumption: Consumption = new Consumption();
export const batteryInfo: BatteryInfo = new BatteryInfo();
