import Base from '@/api/Base';
import DeviceMeasurementsData from '@/models/DeviceMeasurementsData';

export default class Consumption extends Base {
  constructor() {
    super(process.env.VUE_APP_CONSUMPTION_API_ROOT_URL);

    this.instance.interceptors.request.use((config) => {
      return config;
    }, (error) => error);
  }

  async getConsumption(installationId: string, date = new Date()): Promise<any> {
    const YMD = date.toISOString().split('T')[0].replace(/-/g, '');

    const { data } = await this.instance.get<any>(`/consumption/date?date=${YMD}&id=${installationId}`);

    try {
      return (data.measurements.consumption as DeviceMeasurementsData[])
        .reduce(
          (measurement, previousValue) => (Number(measurement.key) > Number(previousValue.key)
            ? measurement : previousValue),
          { key: '0', value: 0 }
        );
    } catch (error) {
      return error;
    }
  }
}
