import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/translations/en';
import nl from '@/translations/nl';
import setting from '@/store/settings';

const messages = {
  en,
  nl,
};

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: setting.language,
  messages,
  dateTimeFormats: {
    en: {
      short: {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      },
      dateWithDayOfWeek: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
    },
    nl: {
      short: {
        hour: 'numeric',
        minute: 'numeric',
      },
      long: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
        hour: 'numeric',
        minute: 'numeric',
      },
      dateWithDayOfWeek: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        weekday: 'long',
      },
    },
  },
});

export default i18n;
