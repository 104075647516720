import Store from '../store';
import Base from './Base';
import StatusTotalResponse from './dtos/StatusTotalResponse';
import StatusPerPlantResponse from './dtos/StatusPerPlantResponse';
import { StatusPerDevice, StatusPerDeviceResponse } from './dtos/StatusPerDeviceResponse';

export default class Analytics extends Base {
  constructor() {
    super(process.env.VUE_APP_RESIDENCE_API_ROOT_URL);

    this.instance.interceptors.request.use((config) => {
      const token = Store.getters['auth/getRoleAccessToken'];
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }, (error) => error);
  }

  async getStatusTotal(): Promise<StatusTotalResponse> {
    const { data } = await this.instance.get<StatusTotalResponse>('/v1/availability/totalStatus');

    return data;
  }

  async getStatusPerPlant(): Promise<StatusPerPlantResponse[]> {
    const { data } = await this.instance.get<StatusPerPlantResponse[]>('/v1/availability/statusPerPlant');

    return data;
  }

  async getStatusPerDevice(installationId: number): Promise<StatusPerDevice[]> {
    const { data } = await this.instance.get<StatusPerDeviceResponse>(`/v1/installation/${installationId}/statusPerDevice`);

    return data.data;
  }
}
