import axios, { AxiosResponse, AxiosInstance, CancelTokenSource } from 'axios';
import ApiError from '@/errors/Api';
import UnauthorizedError from '@/errors/Unauthorized';
import ValidationError from '@/errors/Validation';
import NotFoundError from '@/errors/NotFound';
import Canceled from '@/errors/Cancelled';
import ProblemDetails from '@/api/dtos/ProblemDetails';
import Store from '../store';

export default class Base {
  public instance: AxiosInstance;

  protected baseURL: string;

  private cancelTokenSource: CancelTokenSource | null;

  constructor(baseURL: string) {
    this.baseURL = baseURL;
    this.instance = axios.create({ baseURL });
    this.cancelTokenSource = null;

    this.instance.interceptors.response.use(Base.handleResponse, Base.handleError);
  }

  public cancelRequest(): void {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Request cancelled by the user.');
      this.cancelTokenSource = null;
    }
  }

  public async getCancellableRequest<T>(url: string): Promise<AxiosResponse<T>> {
    this.cancelRequest();
    this.cancelTokenSource = axios.CancelToken.source();

    const response = await this.instance.get<T>(url, {
      cancelToken: this.cancelTokenSource.token,
    });

    return response;
  }

  public static handleResponse(response: AxiosResponse): AxiosResponse<any> {
    return response;
  }

  public static handleError(error: any): Promise<void> {
    if (axios.isCancel(error)) {
      console.info('Request was cancelled by the user.');
      return Promise.reject(new Canceled());
    }

    const {
      response: { status, data } = { status: 0, data: {} },
    }: { response: { status: number, data: unknown } } = error;
    let msg = '?';
    switch (status) {
      case 401:
        if (Store.getters['auth/getAccessToken']) {
          Store.dispatch('auth/logout');
          location.reload(); // eslint-disable-line no-restricted-globals
        }
        return Promise.reject(new UnauthorizedError(error));
      case 404:
        return Promise.reject(new NotFoundError(status, data, error));
      case 412:
        return Promise.reject(new ValidationError(error));
      case 422:
        // Laravel error
        try {
          const errors = error.response.data.errors || false;
          if (errors) {
            msg = errors[Object.keys(errors)[0]];
          }
        } catch (er) {
          msg = 'Could not parse';
        }
        return Promise.reject(new ValidationError(msg));
      default:
        return Promise.reject(ProblemDetails.isTypeConsistent(data)
          ? new ProblemDetails(data)
          : new ApiError(status, data, error));
    }
  }
}
