import Base from '@/api/Base';
import DataView from '@/api/dtos/DataView';
import moment from 'moment';
import Store from '../store';

export default class Meas extends Base {
  constructor() {
    super(process.env.VUE_APP_RESIDENCE_API_ROOT_URL);
    this.instance.interceptors.request.use((config) => {
      const token = Store.getters['auth/getRoleAccessToken'];
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  }

  async getMeasurements(deviceId: string, types: string[], from: Date, resolutionLevelL = 'Original', to: Date = new Date()): Promise<DataView> {
    const formattedFrom = moment(from).format('YYYY-MM-DDTHH:MM');
    const formattedTo = moment(to).format('YYYY-MM-DDTHH:MM');
    const formattedTypes = types.join('&types=');
    const url = `/v2/measurements?deviceId=${deviceId}&resolutionLevel=${resolutionLevelL}&from=${formattedFrom}&to=${formattedTo}&types=${formattedTypes}`;
    const { data } = await this.instance.get<DataView>(url);

    return data;
  }
}
