import debounce from 'lodash.debounce';

const debouncedAlert = debounce((message: string): void => {
  // eslint-disable-next-line no-alert
  alert(message);
}, 500);

export default (errorType: string, error: Error): void => {
  console.error(errorType, error);
  debouncedAlert(error.message);
};
