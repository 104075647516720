import { AxiosRequestConfig } from 'axios';
import UserDetails from '@/models/UserDetails';
import Store from '../store';
import Base from './Base';
import BeginRegistrationRequest from './dtos/BeginRegistrationRequest';
import BeginRegistrationResponse from './dtos/BeginRegistrationResponse';
import ConfirmRegistrationRequest from './dtos/ConfirmRegistrationRequest';
import LoginRequest from './dtos/LoginRequest';
import LoginResponse from './dtos/LoginResponse';
import SetPasswordRequest from './dtos/SetPasswordRequest';
import UpdateUserRequest from './dtos/UpdateUserRequest';
import UserResponse from './dtos/UserResponse';
import CustomerAccountResponse from './dtos/CustomerAccountResponse';
import InstallerEmployeeResponse from './dtos/InstallerEmployeeResponse';
import EmployeeDetails from './dtos/EmployeeDetails';
import UpdateEmployeeRequest from './dtos/UpdateEmployeeRequest';

export default class Identity extends Base {
  constructor() {
    super(process.env.VUE_APP_IDENTITY_API_NEW_ROOT_URL);
    // TODO: Refactor this interceptor when there is no need to use the id token anymore and access token is used instead by default.
    this.useInterceptor((config) => this.idTokenInterceptor(config));
  }

  authTokenInterceptor: number | undefined;

  async login(email: string, password: string): Promise<LoginResponse> {
    const payload: LoginRequest = {
      email,
      password,
    };
    const { data } = await this.instance.post<LoginResponse>('/api/auth/qontrol/login', payload);

    return data;
  }

  async refreshToken(): Promise<LoginResponse> {
    const { data } = await this.instance.post<LoginResponse>('/api/auth/qontrol/refresh-token');

    return data;
  }

  async getUser(email: string): Promise<UserResponse> {
    this.useInterceptor((config) => this.idTokenInterceptor(config));
    const { data } = await this.instance.get<UserResponse>(`/api/user/installer/${email}`);

    return data;
  }

  async update(email: string, userDetails: UserDetails): Promise<UserResponse> {
    this.useInterceptor((config) => this.idTokenInterceptor(config));
    const payload: UpdateUserRequest = userDetails;

    const { data } = await this.instance.put<UserResponse>(`/api/user/installer/${email}`, payload);

    return data;
  }

  async beginRegistration(email: string): Promise<BeginRegistrationResponse> {
    const payload: BeginRegistrationRequest = {
      email,
    };
    const { data } = await this.instance.post<BeginRegistrationResponse>('/api/auth/qontrol/register', payload);

    return data;
  }

  async confirmRegistration(request: ConfirmRegistrationRequest): Promise<void> {
    await this.instance.post('/api/auth/qontrol/register/confirm', request);
  }

  async forgotPassword(email: string): Promise<void> {
    const { data } = await this.instance.post<void>('/api/v2/password/reset-for-qontrol-user', {
      email,
    }, {
      validateStatus: (status) => [200, 201, 404].includes(status),
    });

    return data;
  }

  async verifyPasswordResetToken(email: string, token: string, createdAt: string): Promise<void> {
    await this.instance.post<void>('/api/v2/password/verify-reset-token', { email, token, createdAt });
  }

  async setPassword(request: SetPasswordRequest): Promise<void> {
    await this.instance.post<void>('/api/v2/password/change-for-qontrol-user', request);
  }

  // eslint-disable-next-line camelcase
  async loginAsCustomer(customerId: string): Promise<{ access_token: string }> {
    this.useInterceptor((config) => this.accessTokenInterceptor(config));

    const { data } = await this.instance.post(`api/v2/auth/customer/${customerId}/preview/login`);

    return data;
  }

  async getCustomerAccountByEmail(email: string): Promise<CustomerAccountResponse> {
    this.useInterceptor((config) => this.accessTokenInterceptor(config));
    const { data } = await this.instance.get<CustomerAccountResponse>(`/api/user/customer/email/${email}`);

    return data;
  }

  async getInstallerEmployees(): Promise<InstallerEmployeeResponse[]> {
    this.useInterceptor((config) => this.accessTokenInterceptor(config));

    const { data } = await this.instance.get<InstallerEmployeeResponse[]>('/api/v2/installer/employees');

    return data;
  }

  async createEmployee(employee: EmployeeDetails): Promise<void> {
    this.useInterceptor((config) => this.accessTokenInterceptor(config));

    await this.instance.post('/api/v2/installer/employee', employee);
  }

  async getInstallerEmployee(employeeId: string): Promise<EmployeeDetails> {
    this.useInterceptor((config) => this.accessTokenInterceptor(config));

    const { data } = await this.instance.get<EmployeeDetails>(`/api/v2/installer/employee/${employeeId}`);

    return data;
  }

  async updateEmployeeByInstaller(employeeId: string, updateRequest: UpdateEmployeeRequest): Promise<void> {
    this.useInterceptor((config) => this.accessTokenInterceptor(config));

    await this.instance.put(`/api/v2/installer/employee/${employeeId}`, updateRequest);
  }

  private useInterceptor(interceptor: (config: AxiosRequestConfig) => AxiosRequestConfig) {
    if (this.authTokenInterceptor !== undefined) {
      this.instance.interceptors.request.eject(this.authTokenInterceptor);
    }
    this.authTokenInterceptor = this.instance.interceptors.request.use((config) => interceptor(config), (error) => error);
  }

  private idTokenInterceptor(config: AxiosRequestConfig) {
    config.withCredentials = true;
    this.setTokenIfExists(Store.getters['auth/getAccessToken'], config);

    return config;
  }

  private accessTokenInterceptor(config: AxiosRequestConfig) {
    config.withCredentials = true;
    this.setTokenIfExists(Store.getters['auth/getRoleAccessToken'], config);

    return config;
  }

  private setTokenIfExists(token: string, config: AxiosRequestConfig) {
    // eslint-disable-next-line no-param-reassign
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
}
