export default class NotFound extends Error {
  constructor(
    protected errorCode: number,
    protected apiResponse: any,
    error: string
  ) {
    super(error);
  }

  get code(): number {
    return this.errorCode;
  }

  get response(): any {
    return this.apiResponse;
  }
}
