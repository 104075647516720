import {
  VuexModule,
  Module
} from 'vuex-class-modules';
import store from '../index';

@Module
class Settings extends VuexModule {
  get language(): string {
    return localStorage.getItem('selectedLanguage') || 'nl';
  }

  setLanguage(value: string): void {
    localStorage.setItem('selectedLanguage', value);
  }
}

export default new Settings({
  store,
  name: 'settings',
});
