import {
  VuexModule,
  Module,
  Mutation
} from 'vuex-class-modules';
import Notification from './Notification';
import store from '../index';

@Module
class Notifications extends VuexModule {
  internalNotifications: Notification[] = [];

  get notifications(): Notification[] {
    return this.internalNotifications;
  }

  @Mutation
  remove(index: number): void {
    this.internalNotifications.splice(index, 1);
  }

  @Mutation
  add({
    title,
    description,
    type = 'info',
  }: Notification): void {
    this.internalNotifications.unshift({
      title,
      description,
      type,
      timestamp: new Date().getTime(),
    });
  }

  @Mutation
  purge(): void {
    const cutoffTimestamp = new Date().getTime() - 8000;

    this.internalNotifications = this.internalNotifications.filter(
      (notification) => notification.timestamp && notification.timestamp > cutoffTimestamp
    );
  }
}

export default new Notifications({
  store,
  name: 'notifications',
});
