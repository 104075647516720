import moment from 'moment';
import Base from '@/api/Base';
import DeviceMeasurements from '@/models/DeviceMeasurements';
import GetBatteryLog from '@/api/dtos/GetBatteryLog';
import DeviceMeasurementsData from '@/models/DeviceMeasurementsData';

export default class Battery extends Base {
  constructor() {
    super(process.env.VUE_APP_BATTERY_API_ROOT_URL);
    this.instance.interceptors.request.use((config) => {
      return config;
    });
  }

  async GetBatteryControlLogsByDeviceIdAndDate(id: string, date: Date):
    Promise<DeviceMeasurements[]> {
    const measKeys = ['power'];

    const startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 1);

    const startDateString = moment(startDate).utc().toISOString();
    const endDateString = moment(endDate).utc().toISOString();

    const { data } = await this.instance.get<GetBatteryLog>(`/batteries/${id}/logs/${startDateString}/${endDateString}`);

    const isSuitableArray = data.measurements.length > 24;

    const inputArray = data.measurements.map((measurement) => {
      const shiftedDate = new Date(measurement.key);
      shiftedDate.setHours(shiftedDate.getHours() - date.getTimezoneOffset() / 60);
      if (isSuitableArray) {
        return {
          key: moment(shiftedDate).format('YYYYMMDDHHmmss'),
          value: measurement.value,
        };
      }
      return {
        key: shiftedDate,
        value: measurement.value,
      };
    }).filter((obj) => obj.value !== null);

    if (isSuitableArray) {
      return measKeys.map((measKey) => {
        return {
          key: measKey,
          data: inputArray,
        };
      });
    }
    const today = new Date();
    const isCurrentDate = today.toDateString() === date.toDateString();
    const hourRange = Array.from({ length: isCurrentDate ? new Date().getHours() + 1 : 25 }, (_, i) => i);
    let previousValue = 0;
    const deviceMeasurements: DeviceMeasurementsData[] = [];
    hourRange.forEach((hour) => {
      const matchingObjects = inputArray.filter((obj) => {
        const objHour = new Date(obj.key).getHours();
        return objHour === hour;
      });
      if (matchingObjects.length !== 0) {
        matchingObjects.forEach((meas) => {
          previousValue = meas.value;
          deviceMeasurements.push({ key: moment(meas.key).format('YYYYMMDDHHmmss'), value: previousValue });
        });
      } else {
        deviceMeasurements.push({
          key: moment(date).set({
            hour,
            minute: 0,
            second: 0,
          }).format('YYYYMMDDHHmmss'),
          value: previousValue,
        });
      }
    });
    return measKeys.map((measKey) => {
      return {
        key: measKey,
        data: deviceMeasurements,
      };
    });
  }
}
