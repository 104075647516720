
import { Component, Vue } from 'vue-property-decorator';
import { FeatureToggleComponent as feature } from 'vue-feature-toggle';
import Highcharts from 'highcharts';
import constants from '@/constants';
import moment from 'moment';

const clearCache = () => {
  caches.keys()
    .then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))));
};

@Component
export default class App extends Vue {
  beforeCreate(): void {
    const storedVersion = localStorage.getItem('appVersion');
    console.info('Qontrol Version: ', storedVersion);

    if (process.env.VUE_APP_VERSION !== storedVersion) {
      console.info('New Qontrol version detected');
      localStorage.clear();
      sessionStorage.clear();
      clearCache();
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
      localStorage.setItem('appVersion', process.env.VUE_APP_VERSION);
    }

    // Feature toggles
    const showAppStatus = !!JSON.parse(process.env.VUE_APP_FEATURES_CUSTOMER_SHOW_APP_STATUS) || false;
    feature.visibility(constants.FEATURES.CUSTOMER.SHOW_APP_STATUS, showAppStatus);
  }

  mounted(): void {
    this.$watch(
      '$i18n.locale',
      (newLocale, oldLocale) => {
        if (newLocale !== oldLocale) {
          Highcharts.setOptions(this.$i18n.t('chart') as Highcharts.Options);
          moment.locale(newLocale);
        }
      },
      { immediate: true }
    );
  }
}
