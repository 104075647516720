import { MessageContext } from 'vue-i18n';

export default {
  test: 'TESTTTT',
  common: {
    save_cta: 'Gegevens opslaan',
    save_logo: 'Logo opslaan',
    cancel_cta: 'Annuleren',
    page_not_found: 'Pagina niet gevonden',
    changes_saved: 'Wijzigingen opgeslagen',
    error: 'Foutmelding',
    error_message: 'Er is een fout opgetreden. Probeer het later opnieuw of neem contact op met de ondersteuning.',
    settings: 'Instellingen',
    search: 'Zoeken',
    try_again: 'Probeer het alstublieft nog een keer',
    step: 'Stap',
    back: 'Vorige',
    next: 'Volgende',
    redirect_message: 'Je wordt over {seconds} seconden naar {page} gebracht.',
    this_link: 'deze link',
  },
  validations: {
    empty_field: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} mag niet

    leeg zijn`,
    minimum_characters: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} moet
    minimaal ${ctx.values[0]} tekens lang zijn`,
    maximum_characters: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} mag
    maximaal ${ctx.values[0]} tekens lang zijn`,
    invalid_characters: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} mag
    alleen bestaan uit 0-9, a-z, A-Z, en de volgende symbolen: @ # $ % ^ & * - _ ! + = [ ] { }
    | \\ : ‘ , . ? / \` ~ " ( ) ;`,
    character_combinations: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)}
    vereist drie van de volgende: Kleine letters, Hoofdletters, Nummers, Symbolen`,
    same_as: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} moet hetzelfde
    zijn als ${ctx.values[0]}`,
    not_checked: (ctx: MessageContext): string => `${ctx.linked(ctx.values.field)} moet worden
    aangekruist`,
    invalid_email: 'Het ingevoerde e-mailadres is ongeldig',
    invalid_postal_code: 'De ingevoerde postcode is ongeldig',
    invalid_coc: 'Het ingevoerde KvK-nummer is ongeldig',
    invalid_vat: 'Het ingevoerde btw-nummer is ongeldig',
    invalid_phone_number: 'Het ingevoerde telefoonnummer is ongeldig',
    invalid_iban: 'Het ingevoerde IBAN nummer is ongeldig',
    invalid_token: 'Uw verificatielink of token is ongeldig. Probeer het alstublieft nog een keer',
    incorrect_email_or_password: 'Incorrect e-mailadres of wachtwoord',
    already_logged_in: 'Gebruiker is al ingelogd',
    email_not_found: 'Ongeldig e-mailadres',
    email_already_in_use: 'Dit e-mailadres is al in gebruik',
    field_is_not_valid_peak_number: 'Voer het vermogen van de installatie in met een komma of ' +
      'een punt.',
    image_too_large: 'De afbeelding is te groot. De maximale grootte is 1 MB.',
    wrong_file_format: 'Het bestandsformaat wordt niet ondersteund. Upload een JPG-, JPEG- of PNG-afbeelding.',
    invalid_street_number: 'Het ingevulde huisnummer os ongeldig',
  },
  auth: {
    login_cta: 'Inloggen',
    log_out_cta: 'Uitloggen',
    no_account_cta: 'Ik heb nog geen account',
    register_cta: 'Aanmelden',
    send_reset_cta: 'Verzend herstellink',
    confirm_cta: 'Bevestig',
    email_label: 'E-mailadres',
    password_label: 'Wachtwoord',
    new_password_label: 'Nieuw Wachtwoord',
    new_password_confirm_label: 'Bevestig nieuw wachtwoord',
    back_to_login: 'Terug naar inloggen',
    forgot_password: 'Wachtwoord vergeten',
    reset_password: 'Herstel uw wachtwoord',
    password_reset: 'Wachtwoordherstel aangevraagd',
    password_reset_message: 'Er is een herstellink gestuurd naar: {email}',
    password_reset_token_invalid: 'De link om het wachtwoord in te stellen / te resetten is ongeldig.',
    password_reset_token_expired: 'De link om het wachtwoord in te stellen / te resetten is verlopen.',
    create_account: 'Een account aanmaken',
    account_created: 'Uw account is aangemaakt!',
    account_created_message: 'Er is een verificatiemail verstuurd naar uw e-mailadres.',
    choose_password: 'Kies een nieuw wachtwoord',
    password_set: 'Uw wachtwoord is aangemaakt!',
    accept_terms_label: 'Ik accepteer de {0}',
    terms_and_conditions: 'voorwaarden',
    login_error: 'Fout bij het inloggen',
    login_page: 'de login pagina',
  },
  onboarding: {
    missing_data: 'Op deze pagina vind je jouw persoonlijke dashboard. Zoals je ziet is die ' +
      'momenteel nog leeg. Voordat we jouw data op kunnen halen, hebben we namelijk eerst wat ' +
      'gegevens van je nodig.',
    setup_account: 'Laten we beginnen met een account.',
    setup_account_2: 'Graag spreken we je aan met je voornaam en willen we weten hoe we je ' +
      'kunnen bereiken. Het invullen van jouw gegevens duurt maar een minuut.',
    setup_account_button: 'Vul jouw algemene gegevens in',
    setup_company: 'Wat fijn dat we je met je voornaam kunnen aanspreken. De basis van je ' +
      'profiel is gelegd. Waarschijnlijk ben je hier om inzicht te krijgen in jouw installaties. ' +
      'Vul snel jouw bedrijfsprofiel in, zodat wij je data op kunnen halen.',
    setup_company_button: 'Vul het bedrijfsprofiel in',
    setup_installer_profile: 'Om je installatie nog beter onder de aandacht te brengen, heb ' +
      'je de mogelijkheid om te bepalen welke informatie de klant ziet in de Bliq-app over jouw bedrijf. Vul snel de bedrijfspagina in en publiceer jouw data.',
    setup_installer_profile_button: 'Vul de bedrijfspagina in',
    log_out: 'Dank je wel voor het registreren van je bedrijf bij Bliq! Om je account volledig ' +
      'te activeren vragen we je vragen om uit te loggen en opnieuw in te loggen. Vanaf dat ' +
      'moment is het mogelijk om nieuwe installaties aan te maken en ze te beheren. Na het ' +
      'opnieuw inloggen kun je de registratie van je bedrijf afronden.',
  },
  dashboard: {
    dashboard: 'Dashboard',
    dashboard_page: 'het dashboard',
    hi: 'Hi',
    welcome_header: 'Welkom in Qontrol!',
    welcome_message: 'Welkom op jouw dashboard.',
    no_notifications: 'Er zijn momenteel geen meldingen.',
    logout: 'Uitloggen',
    my_account: 'Mijn account',
    my_company: 'Mijn bedrijf',
    my_company_page: 'Mijn Bedrijfspagina',
    status_online: 'Installaties online',
    status_offline: 'Installaties offline',
    status_degraded: 'Communicatie problemen',
    status_incomplete: 'Incompleet',
    status_notTested: 'Installatie niet getest',
    status_failed: 'Installatie test mislukt',
    my_users: 'Mijn gebruikers',
    third_parties: 'Externe organisatie',
  },
  widgets: {
    communication_status: 'Communicatie status',
    plants: 'installaties',
  },
  availability: {
    all: 'Alles',
    online: 'Online',
    incomplete: 'Incompleet',
    degraded: 'Verslechterd',
    offline: 'Offline',
    unknown: 'Onbekend',
  },
  setup: {
    register_customer: 'Klant aanmelden',
    register_device: 'Bliq-vanger aanmelden',
    register_installation: 'Installatie aanmelden',
    new_installation: 'Nieuwe installatie',
    address: {
      add_address: 'Adres toevoegen',
      country: 'Land',
      country_nl: 'Nederland',
      country_be: 'België',
      postal_code: 'Postcode',
      house_number: 'Huisnummer',
      house_number_extention: 'Toev.',
      street: 'Straatnaam',
      city: 'Stad',
      invalid_postal_code: 'De ingevoerde postcode is ongeldig',
    },
    bliqvanger: {
      register_bliqvanger: 'Bliq-vanger aanmelden',
      add_bliqvanger: 'Bliq-vanger toevoegen',
      peak_power: 'Piekvermogen (kWp)',
      scan: 'Scan QR code',
      cancel_scan: 'Afbreken',
      add_manually: 'Handmatig toevoegen',
      serial_number: 'Serienummer Bliq-vanger',
      add: 'Toevoegen',
      added_bliqvangers: 'Toegevoegde Bliq-vangers:',
      added_bliqvanger: 'Toegevoegde Bliq-vanger:',
      added_bliqvanger_success: 'De Bliq-vanger is toegevoegd aan de installatie',
      device_serial_number_invalid: 'Ingevulde serienummer van het apparaat is ongeldig.',
      device_serial_number_present: 'Ingevulde serienummer staat al in de lijst.',
      'collector_error_DEVICE-0001': 'Bliq-vanger met het opgegeven serienummer kon niet ' +
        'worden gevonden.',
      'collector_error_DEVICE-0002': 'Bliq-vanger met het opgegeven serienummer bestaat al.',
      'collector_error_DEVICE-INSTALL-0002': 'Installatie kon niet worden gevonden in IGEN-portal.',
    },
    customer: {
      register_customer: 'Klant aanmelden',
      first_name: 'Voornaam',
      last_name: 'Achternaam',
      phone_number: 'Telefoonnummer',
    },
    overview: {
      overview: 'Overzicht',
      success: 'Succes!',
      new_device_is_paired: 'Jouw Bliq-vanger wordt nu gekoppeld.',
      new_device_additional_message: 'Het kan een paar seconden duren. Vernieuw de pagina als ' +
        'u uw installatie niet in de lijst ziet.',
      name: 'Naam',
      address: 'Adres',
      email_address: 'E-mail',
      phone_number: 'Telefoonnummer',
      bliqvanger: 'Bliq-vanger',
      peak: 'Piekvermogen',
    },
    connection: {
      installation_attributes: 'Installatie kenmerken toevoegen',
      installer_id: 'Installateur ID',
    },
    installations: {
      must_start_from_first_step: 'U moet vanaf de eerste stap beginnen met het invullen van ' +
        'het formulier',
    },
  },
  installations: {
    installations: 'Installaties',
    installation: 'Installatie',
    new_installation: 'Nieuwe installatie',
    customer: 'Klant',
    address: 'Adres',
    status: 'Status',
    production_capacity: 'Capaciteit (kWp)',
    device: 'Apparaat',
    devices: 'Apparaten',
    device_id: 'Apparaat ID:',
    error_loading_power: 'Fout bij laden van gegevens',
    status_error: 'Kan de installatiestatus niet ophalen',
    installations_error: 'Kan de installaties niet ophalen',
    device_no_data: 'Geen gegevens',
    device_no_recent_measurements: 'Geen actuele gegevens',
    device_status_error: 'Fout bij ophalen van apparaat status',
    monitoring_view: 'Monitoring',
    schematic_view: 'Schematisch overzicht',
    list_view: 'Lijst overzicht',
    production_consumption: 'Productie / consumptie (W)',
    reseller_id: 'Reseller ID',
    nstallerName: 'Installateur',
    smartMeter: 'Slimme meter',
    battery: 'Batterij',
    registration: 'Registratie',
    installationDate: 'Datum installatie',
    force_sync_devices: 'Forceer synchronisatie-apparaten',
    force_sync_devices_in_progress: 'Geen aangesloten apparaten gevonden. Controleer de configuratie.',
    not_tested: 'Installatie niet getest',
    failed: 'Installatie test mislukt',
    no_data: 'Geen gegevens',
    not_tested_no_data: 'Er zijn geen niet geteste installaties',
    failed_no_data: 'Er zijn geen mislukte installaties',
    test: 'Test',
  },
  measurements: {
    power: 'W',
    pv_power: 'W',
    battery_power: 'W',
    prices: '€',
    export: 'kWh',
    battery_soc: '%',
  },
  devices: {
    usage: 'Verbruik',
    power: 'vermogen',
    pv_power: 'vermogen',
    battery_power: 'vermogen',
    prices: 'Uurprijzen',
    battery_soc: 'laadstatus',
    export: 'productie',
    buying_selling: 'In- en verkoop',
    devices: 'Apparaten',
    bliq: 'Bliq',
    smart_meter: 'Slimme Meter',
    ev_charger: 'Laadpaal',
    airco: 'Airco',
    heat_pump: 'Warmtepomp',
    house: 'Stroomverbruik',
    grid: 'Slimme meter',
    battery: 'Batterij',
    solar_panel: 'Zonnepaneel',
    solar_panels: 'Zonnepanelen',
    collector: 'Bliq-vanger',
    gas_meter: 'Gasmeter',
    inverter: 'Omvormer',
    type_rf_sub_device: 'RF Dongel',
    kwh_meter: 'Bliq-kWh-meter',
    meter: 'Bliq-kWh-meter',
    cta_edit: 'Bewerken',
    cta_save: 'Opslaan',
    add_label: 'Nieuw label toevoegen',
    label: 'Labdfsel',
    change_config: 'De configuratie van het apparaat wijzigen',
    basic_info: 'Algemene informatie',
    attention_needed: 'Aandacht nodig!',
    kwh_meter_config_needed: 'We zien dat je een kWh meter hebt aangesloten. Om de informatie ' +
      'in de app en Qontrol goed te kunnen weergeven is het nodig dat je een keuze maakt hoe ' +
      'je deze wilt gaan gebruiken of aan welk apparaat je hem hebt aangesloten.',
    inverter_config_needed: 'Het lijkt erop dat deze omvormer onderdeel is van een systeem. ' +
      'Selecteer de situatie die van toepassing is:',
    connected_device: 'Aangesloten apparaat',
    select_device: 'Selecteer het apparaat',
    subtype_success: 'Het subtype van het apparaat is gewijzigd',
    change_error: 'Helaas, de gegevens konden niet worden opgeslagen. Probeer het opnieuw. ' +
      'Lukt het niet neem dan contact met ons op via support@bliq.energy',
    label_success: 'Het label van het apparaat is gewijzigd',
    device_error: 'Kan de apparaatdetails niet ophalen',
    inverter_only: 'Alleen zonnepanelen',
    battery_only: 'Alleen batterij',
    battery_and_inverter: 'Zonnepanelen met batterij',
    inverter_brand: 'Merk van de omvormer',
    battery_brand: 'Merk van de batterij',
    inverter_type: 'Type omvormer',
    battery_type: 'Type batterij',
    number_of_batteries: 'Aantal batterij modules',
    select_type: 'Selecteer het type',
    select_brand: 'Selecteer het merk',
    batteries_amount: 'Aantal batterij modules',
    not_now_button: 'Niet nu',
    ok_now_button: 'Volgend apparaat configureren',
    you_have_unconfigured_devices: 'Je hebt {number} niet-geconfigureerde apparat(en).',
    configure_your_devices: 'Configureer al uw apparaten om hun details te zien.',
    invalid_manager_configuration: 'Verkeerde managerconfiguratie. Alle apparaten moeten dezelfde beheerder hebben',
    self_test: {
      self_test_explanation: ' Voor meer informatie over de zelftest en eventueel niet slagende tests verwijzen wij naar ons kennisbank artikel via {self-test-link}' +
        'Een zelftest kan maximaal 20 minuten duren. Als de test langer duurt dan kan het scherm gesloten worden en is de test  niet geslaagd.',
      self_test_result_title: 'Zelftest resultaat {date}',
      run_self_test: 'Zelftest uitvoeren',
      abort_self_test: 'Zelftest afbreken',
      update_frequency: 'Data frequentie Bliq-vanger ({serialNumber}) instellen op 1 minuut ',
      set_inverter_standard_settings: 'Instellingen omvormer wijzigen voor batterij-aansturing',
      check_smart_meter_installed: 'Controle op aanwezigheid van slimme meter',
      send_battery_power_command: 'Stuur een commando om de batterij op te laden/ontladen',
      queued_for_testing: 'In wachtrij voor testen',
      testing: 'Test gestart op {startedAt} ...',
      test_failed: 'Test niet succesvol om {time} uur. Duur: {duration}',
      test_succeeded: 'Succesvol getest om {time} uur. Duur: {duration}',
      test_not_ready: 'Test is nog niet klaar om te starten',
      test_unknown: 'Het testresultaat is niet bekend',
      test_summary_result: 'Test gestart op {startedAt} door {executedBy} en afgerond op {finishedAt}. Duur: {duration}.',
      test_summary_status_passed: 'Status: Geslaagd.',
      test_summary_status_failed: 'Status: Mislukt.',
      test_summary_status_passed_manually_adjusted: 'Status: Geslaagd (Handmatig aangepast).',
      test_summary_status_failed_manually_adjusted: 'Status: Mislukt (Handmatig aangepast).',
      cant_run_test_tite: 'Kan de zelftest nu niet uitvoeren',
      cant_run_test_description: 'Probeer het later nog eens',
      startedAt: 'Gestart op',
      status: 'Resultaat',
      duration: 'Duur',
      loading_data: 'Gegevens laden...',
      change_status: 'Status aanpassen',
      executed_by: 'Door',
    },
  },
  calendar: {
    sameDay: 'Vandaag',
    lastDay: 'Gisteren',
    nextDay: 'Morgen',
    lastWeek: 'Afgelopen',
    nextWeek: 'Komende',
    day: 'Dag',
    isoWeek: 'Week',
    month: 'Maand',
    year: 'Jaar',
  },
  chart: {
    lang: {
      loading: 'Wordt geladen...',
      months: [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december',
      ],
      weekdays: [
        'zondag',
        'maandag',
        'dinsdag',
        'woensdag',
        'donderdag',
        'vrijdag',
        'zaterdag',
      ],
      shortMonths: [
        'jan',
        'feb',
        'maa',
        'apr',
        'mei',
        'jun',
        'jul',
        'aug',
        'sep',
        'okt',
        'nov',
        'dec',
      ],
      thousandsSep: '.',
      decimalPoint: ',',
      resetZoom: 'Reset',
    },
  },
  customers: {
    customer: 'Klant',
    customers: 'Klanten',
    new_customer: 'Nieuwe klant',
    no_data: 'Er zitten nog geen klanten in jouw bestand.',
    no_data2: 'Klik op de knop rechtsboven om een nieuwe klant toe te voegen.',
    no_search_results: 'Geen zoekresultaten gevonden.',
    installation_date: 'Installatiedatum',
    customers_error: 'Kan de klanten niet ophalen',
    name_or_postcode: 'Naam of postcode',
    search_by_name_or_postcode: 'Zoek op naam of postcode',
    customer_not_found: 'Geen juiste resultaten?  Dan heeft jouw klant zich nog niet aangemeld ' +
      'bij Bliq.',
    no_results: 'Geen resultaat?',
    customer_not_registered: 'Dan heeft jouw klant zich nog niet aangemeld bij Bliq. Kies dan ' +
      'voor klant aanmelden.',
    customer_no_data: 'Geen bestaande gegevens?  Dan heeft jouw klant zich nog niet aangemeld ' +
      'bij Bliq. Kies dan voor',
    start_installation: 'Start installatie',
    details: {
      customer_error: 'Kan klantgegevens niet ophalen',
      customer_data: 'Klantgegevens',
      name: 'Naam',
      customer_id: 'Klant ID',
      address_data: 'Adresgegevens',
      address: 'Adres',
      billing_address: 'Factuuradres',
      go_to_installation: 'Bekijk installatie',
      add_collector: 'Start installatie',
      add_BV: 'Start',
      email: 'E-mail',
      phone_number: 'Telefoon nr.',
      installation_id: 'Installatie ID',
      installer_name: 'Installateur',
      installation_created_date: 'Datum aangemaakt',
      installation_created_by: 'Aangemaakt door',
      installation_updated_date: 'Datum gewijzigd',
      installation_updated_by: 'Gewijzigd door',
      registration_process_status: 'Onboarding',
      energy_company: 'Energiemaatschappij',
      energy_contract: 'Energiecontract',
      subscription_product: 'Product',
      subscription_start_date: 'Start abonnement',
      appStatus: 'App status',
      appConnected: 'App verbonden',
      appConnectedInstallation: 'App verbonden met installatie',
      appNotConnected: 'Er is geen app gekoppeld aan dit e-mailadres',
      watcherFunction: 'Watcher-functie',
    },
  },
  users: {
    users: 'Gebruikers',
    account: 'Account',
    my_account: 'Mijn account',
    general_information: 'Algemene gegevens',
    first_name: 'Voornaam',
    last_name: 'Achternaam',
    email_address: 'E-mailadres',
    phone_number: 'Telefoonnummer',
    language: 'Taal',
    language_en: 'Engels',
    language_nl: 'Nederlands',
    general_information_saved: 'Je algemene gegevens zijn succesvol opgeslagen.',
    change_password: 'Wachtwoord wijzigen',
    password: 'Wachtwoord',
    current_password: 'Huidige wachtwoord',
    new_password: 'Nieuw wachtwoord',
    repeat_new_password: 'Herhaal nieuw wachtwoord',
    password_saved: 'Je wachtwoord is succesvol opgeslagen',
    rights: 'Gebruikersrechten',
    give_the_user_admin_rights: 'Geef de gebruiker administratierechten',
    block_user: 'Blokkeer gebruiker',
    rights_saved: 'De gebruikersrechten zijn succesvol opgeslagen.',
    new_user: 'Nieuwe gebruiker',
    no_data: 'Er zijn nog geen gebruikers.',
    no_data_sub_text: 'Klik op de knop rechts boven om een gebruiker toe te voegen.',
    already_exists: 'Gebruiker bestaat al',
  },
  companies: {
    companies: 'Bedrijven',
    company_profile: 'Bedrijfsprofiel',
    country: 'Land',
    company_info: 'Bedrijfsgegevens',
    coc_number: 'KvK-nummer',
    company_name: 'Bedrijfsnaam',
    address: 'Adresgegevens',
    postal_code: 'Postcode',
    house_number: 'Huisnummer',
    house_number_extention: 'Toev.',
    street: 'Straatnaam',
    town: 'Stad',
    financials: 'Financiële gegevens',
    vat_number: 'Btw-nummer',
    iban_number: 'IBAN Nummer',
    country_nl: 'Nederland',
    country_be: 'België',
    new_company: 'Nieuw bedrijf',
    data_policy: 'datauitwisselingsvoorwaarden',
    company_saved: 'Het bedrijfsprofiel is succesvol opgeslagen.',
    company_logo_saved: 'Het bedrijfslogo is succesvol opgeslagen.',
    company_published: 'De bedrijfspagina is gepubliceerd.',
    company_unpublished: 'De bedrijfspagina is offline gehaald.',
    postal_code_not_found: 'De ingevoerde postcode is niet gevonden, controleer alstublieft ' +
      'uw invoer en probeer het nog een keer. Als de ingevulde postcode correct is, vul dan ' +
      'alstublieft de straatnaam en stad handmatig in.',
    last_update: 'Laatste update',
    published: 'Gepubliceerd',
    publish: 'Publiceer',
    hidden: 'Verborgen',
    visible: 'Zichtbaar',
    visibility: 'Zichtbaarheid',
    my_company_page: 'Mijn  Bedrijfspagina',
    see_the_preview: 'Bekijk de preview',
    publish_your_company_page: 'Publiceer jouw bedrijfspagina',
    unpublish_your_company_page: 'Haal offline',
    intro: 'Via deze pagina bepaal je welke informatie jouw klant in de Bliq-app ziet over ' +
      'jouw bedrijf.',
    heading_2: 'Hoe werkt het?',
    text: 'Bepaal welke informatie je met jouw klanten wilt delen op de Mijn installateur pagina in de app. Bedrijfsnaam, Telefoon en E-Mail zijn verplicht, ' +
      'de andere velden zijn optioneel. Via de voorvertoning kun je zien hoe de info, inclusief logo, er voor de klant uit zal zien. ' +
      'Is het in orde? Dan kun je de informatie publiceren. Je kunt er ook voor kiezen om de informatie weer te verbergen.',
    info_alert: 'De informatie die op deze pagina beheerd wordt, is direct zichtbaar voor de ' +
      'Bliq-app gebruiker. Let er dan ook op dat de contactgegevens die hier in worden gevuld ' +
      'voor jullie klanten zijn.',
    call_the_mechanic: 'Bel de monteur',
    visit_us: 'Bezoek ons',
    email_us: 'Mail ons',
    call_us: 'Bel ons',
    we_are_here: 'Wij zijn hier gevestigd',
    no_logo_text: 'Je hebt nog geen logo toegevoegd',
    logo_upload: 'Logo uploaden',
    change_logo: 'Logo aanpassen',
    company_already_exists: 'Er bestaat al een account voor dit bedrijf, het is niet mogelijk ' +
      'om een tweede account aan te maken. Neem contact op met je collega\'s.',
    need_help: 'Hulp nodig? Neem contact op via support@bliq.energy.',
  },
  appInstaller: {
    info: 'Bedrijfsgegevens',
    preview_company_page: 'Voorbeeld van uw pagina',
    visit: 'Bezoekadres',
    companyName: 'Bedrijfsnaam',
    website: 'Website',
    email: 'E-mailadres',
    phone: 'Telefoonnummer',
    street: 'Straat',
    house_number: 'Huisnummer',
    additions: 'Toevoeging',
    zipcode: 'Postcode',
    city: 'Woonplaats',
  },
  third_parties: {
    already_exists: 'Externe organisatie bestaat al',
    organization_name: 'Naam organisatie',
    new_third_party: 'Nieuwe externe organisatie',
    no_data: 'Er zijn nog geen externe organisaties.',
    no_data_sub_text: 'Gebruik de knop rechts boven om een externe organisatie aan te maken.',
  },
};
