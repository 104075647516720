import Vue from 'vue';

Vue.filter('formatBrand', (value: string) => {
  if (!value) {
    return '';
  }

  return value.replaceAll('_', ' ');
});

Vue.filter('capitalize', (value: string) => {
  if (!value) {
    return '';
  }

  const splitToString = value.toLowerCase().split(' ');
  const formatted: string[] = [];

  splitToString.forEach((string) => {
    formatted.push(string.charAt(0).toUpperCase() + string.slice(1));
  });

  return formatted.join(' ');
});
