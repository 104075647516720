import Company from '@/models/Company';
import CompanyDetails from '@/models/CompanyDetails';
import ConfiguredDevice from '@/models/ConfiguredDevice';
import InstallationSelfTestStatus from '@/api/dtos/InstallationSelfTestStatus';
import FlatCustomerInstallationDetails from '@/api/dtos/FlatCustomerInstallationDetails';
import AfasInstallation from '@/api/dtos/AfasInstallation';
import NewInstallation from '@/models/NewInstallation';
import NewInstallationResponse from '@/api/dtos/NewInstallationResponse';
import NewInstallationRequest from '@/api/dtos/NewInstallationRequest';
import constants from '@/constants';
import AddBliqVangerModel from '@/models/AddBliqVanger';
import AddBliqVangerRequest from '@/api/dtos/AddBliqVangerRequest';
import Customer from '@/api/dtos/Customer';
import { Subscription } from '@/api/dtos/CustomerContact';
import SubscriptionResponse from '@/api/dtos/SubscriptionResponse';
import Store from '../store';
import Base from './Base';
import CompanyResponse from './dtos/CompanyResponse';
import GetAddressFromPostcodeAndHouseNumberResponse from './dtos/GetAddressFromPostcodeAndHouseNumberResponse';
import Device from './dtos/Device';
import DeviceStatuses from './dtos/DeviceStatuses';
import DeviceSelfTestResponse from './dtos/DeviceSelfTestResponse';
import DevicesResponse from './dtos/DevicesResponse';
import PageableResponse from './dtos/PageableResponse';
import { InstallerDetailsRequest, InstallerDetailsResponse } from './dtos/InstallerDetails';
import { SelfTestStepStatus } from './dtos/SelfTestStepStatus';
import InstallationChangeDetails from './dtos/InstallationChangeDetails';
import OrganizationDetails from './dtos/OrganizationDetails';

export default class Residence extends Base {
  constructor() {
    super(process.env.VUE_APP_RESIDENCE_API_ROOT_URL);

    this.instance.interceptors.request.use((config) => {
      const token = Store.getters['auth/getRoleAccessToken'];
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    }, (error) => error);
  }

  async get(companyId: string): Promise<Company> {
    const { data } = await this.instance.get<CompanyResponse>(`/v1/company/${companyId}`);

    return data;
  }

  async create(company: Company): Promise<Company> {
    const modifiedPayload: Company = {
      ...company,
      cocNumber: company.cocNumber.replace(/\D/g, ''),
      details: {
        ...company.details,
        vatNumber: company.details.vatNumber?.replace(/\W/g, ''),
        ibanNumber: company.details.ibanNumber?.replace(/\W/g, ''),
      },
    };

    const { data } = await this.instance.post<CompanyResponse>('/v1/company', modifiedPayload);

    return data;
  }

  async update(company: Company): Promise<void> {
    const modifiedPayload: CompanyDetails = {
      ...company.details,
      country: company.details.country.toLowerCase(),
      vatNumber: company.details.vatNumber?.replace(/\W/g, ''),
      ibanNumber: company.details.ibanNumber?.replace(/\W/g, ''),
    };

    await this.instance.put(`/v1/company/${company.id}`, modifiedPayload);
  }

  async getAddressForPostcodeAndHouseNumber(
    postcode: string,
    houseNumber: number
  ): Promise<GetAddressFromPostcodeAndHouseNumberResponse> {
    const { data } = await this.instance.get<GetAddressFromPostcodeAndHouseNumberResponse>(`/v1/postcode/${postcode.replace(' ', '')}/${houseNumber}`);

    return data;
  }

  async getDevicesByInstallationId(installationId: string, forceSync = false): Promise<Device[]> {
    // we don't put the forceSync param in the params object because we don't want to override the default value on the backend
    const { data } = await this.instance.get<DevicesResponse>(`/v2/device/installation/${installationId}${forceSync ? '?forceSync=true' : ''}`);

    return data.devices;
  }

  async getDeviceStatusesByIgenInstallationIdList(ids: string[]): Promise<Record<string, DeviceStatuses>> {
    const { data } = await this.instance.get<Record<string, DeviceStatuses>>('/v1/device/statuses', {
      params: { installationIdList: ids.join(',') },
    });

    return data;
  }

  async getDevice(id: number | string): Promise<Device> {
    const { data } = await this.instance.get<Device>(`/v2/devices/${id}`);

    return data;
  }

  async updateDeviceSubType(deviceId: number | string, subType: ConfiguredDevice): Promise<Device> {
    const { data } = await this.instance.patch(`/v1/devices/${deviceId}`, subType);

    return data;
  }

  async updateDeviceLabel(deviceId: number | string, label: string): Promise<Device> {
    const { data } = await this.instance.patch(`/v1/devices/${deviceId}/label`, { label });

    return data;
  }

  async getInverterConfigData(): Promise<Record<string, unknown>> {
    const { data } = await this.instance.get('/v1/battery-inverter-types');

    return data;
  }

  async beginSelfTest(deviceId: string): Promise<DeviceSelfTestResponse> {
    const { data } = await this.instance.post<DeviceSelfTestResponse>(`/v1/device/${deviceId}/self-test`);

    return data;
  }

  async checkSelfTest(id: string): Promise<DeviceSelfTestResponse> {
    const { data } = await this.getCancellableRequest<DeviceSelfTestResponse>(`/v1/self-test/${id}`);

    return data;
  }

  async abortSelfTest(id: string): Promise<DeviceSelfTestResponse> {
    const { data } = await this.instance.post<DeviceSelfTestResponse>(`/v1/self-test/${id}/abort`);

    return data;
  }

  async getSelfTests(deviceId: string, page: number, size: number): Promise<PageableResponse<DeviceSelfTestResponse>> {
    const { data } = await this.getCancellableRequest<PageableResponse<DeviceSelfTestResponse>>(`/v1/device/${deviceId}/self-tests?page=${page}&size=${size}`);

    return data;
  }

  async getInstallerDetails(installerId: string): Promise<InstallerDetailsResponse> {
    const { data } = await this.getCancellableRequest<InstallerDetailsResponse>(`/v1/installers/${installerId}`);

    return data;
  }

  getInstallerLogoUrl(imageId: number): string {
    return `${this.baseURL}/v1/installers/logo/${imageId}`;
  }

  async updateInstallerLogo(installerId: string, image: File): Promise<void> {
    const formData = new FormData();
    formData.append('image', image);
    const response = await this.instance.put(`/v1/installers/${installerId}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status !== 200) {
      throw new Error('Failed to upload logo');
    }
  }

  async updateInstallerDetails(installerId: string, installerDetails: InstallerDetailsRequest): Promise<void> {
    const response = await this.instance.put(`/v1/installers/${installerId}`, installerDetails);

    if (response.status !== 200) {
      throw new Error('Failed to update the installer details');
    }
  }

  async updateSelfTestStatus(id: string, status: SelfTestStepStatus): Promise<void> {
    await this.instance.patch(`/v1/self-test/${id}/status`, { status });
  }

  async getInstallationsSelfTestResults(): Promise<InstallationSelfTestStatus> {
    const response = await this.instance.get<InstallationSelfTestStatus>('/api/v1/installations/self-test/status');

    if (response.status !== 200) {
      throw new Error('Failed to load self test statuses');
    }

    return response.data;
  }

  async getNotTestedInstallations(): Promise<FlatCustomerInstallationDetails[]> {
    const response = await this.instance.get<FlatCustomerInstallationDetails[]>('/api/v1/installations/self-test/not-tested');

    if (response.status !== 200) {
      throw new Error('Failed to load self test statuses');
    }

    return response.data;
  }

  async getFailedInstallations(): Promise<FlatCustomerInstallationDetails[]> {
    const response = await this.instance.get<FlatCustomerInstallationDetails[]>('/api/v1/installations/self-test/failed');

    if (response.status !== 200) {
      throw new Error('Failed to load self test statuses');
    }

    return response.data;
  }

  async getInstallationChangeDetails(installationId: string): Promise<InstallationChangeDetails> {
    const response = await this.instance.get<InstallationChangeDetails>(`/api/v1/installations/${installationId}/changes`);

    if (response.status !== 200) {
      throw new Error('Failed to get installation change details');
    }

    return response.data;
  }

  async getOrganizations(): Promise<OrganizationDetails[]> {
    const { data } = await this.instance.get<OrganizationDetails[]>('/v2/organizations');

    return data;
  }

  async createOrganization(organization: OrganizationDetails): Promise<void> {
    await this.instance.post('/v2/organizations', organization);
  }

  async getInstallation(installationId: string): Promise<AfasInstallation> {
    const { data } = await this.instance.get<AfasInstallation>(`/v1/contacts/installations/${installationId}`);

    return data;
  }

  async getInstallations(): Promise<AfasInstallation[]> {
    const { data } = await this.instance.get<Array<AfasInstallation>>('/v1/contacts/installations');

    return data;
  }

  async saveNewInstallation(
    installation: NewInstallation
  ): Promise<NewInstallationResponse> {
    const payload: NewInstallationRequest = {
      Device_ID: installation.deviceIds,
      First_Name: installation.firstName,
      Last_Name: installation.lastName,
      Postcode: installation.postalCode,
      HouseNumber: installation.houseNumber,
      HouseNumberExt: installation.houseNumberExt,
      Latitude: installation.latitude,
      Longitude: installation.longitude,
      Email: installation.email,
      Mobile: installation.phoneNumber,
      PeakLoad: constants.DEFAULT_PEAK_LOAD,
      Street: installation.street,
      City: installation.city,
      Country: installation.country,
      Installer_ID: installation.installerId,
    };

    const { data } = await this.instance.post<NewInstallationResponse>('/installer/install-new-device/qontrol', payload);

    return data;
  }

  async addBliqVangerToInstallation(
    installation: AddBliqVangerModel
  ): Promise<NewInstallationResponse> {
    const payload: AddBliqVangerRequest = {
      Device_ID: installation.deviceId,
      Installer_ID: installation.installerId,
      afasContactId: installation.afasContactId,
      PeakLoad: constants.DEFAULT_PEAK_LOAD.toString(),
    };
    const { data } = await this.instance.post<NewInstallationResponse>('/installer/bliqvanger', payload);

    return data;
  }

  async getCustomers(): Promise<Customer[]> {
    const { data } = await this.instance.get<Customer[]>('v1/customers/contacts');
    const mappedCustomersList = data.map((fullCustomer, index) => {
      return {
        ...fullCustomer,
        index,
        customer: {
          ...fullCustomer.customer,
          fullAddress: this.getAddress(fullCustomer.customer),
        },
      };
    });
    return mappedCustomersList;
  }

  async getCustomerById(id: string): Promise<any> {
    const customerPromise = this.instance.get<any>(`v1/customer/contact/${id}`);
    const subscriptionPromise = this.getSubscriptionsByCustomerId(id);
    const [customerResponse, customerSubscriptionsResponse] = await Promise.allSettled([customerPromise, subscriptionPromise]);
    const customerData = customerResponse.status === 'fulfilled' ? customerResponse.value.data : {};
    const subscriptionsData = customerSubscriptionsResponse.status === 'fulfilled' ? customerSubscriptionsResponse.value : [];
    const mappedData = {
      customer: {
        ...customerData.customer,
        fullAddress: this.getAddress(customerData.customer),
      },
      contacts: customerData.contacts.map((contact: any) => {
        const subscription: Subscription = subscriptionsData
          .filter((value) => value.afasContactId === contact.afasContactId)
          .map((value) => ({ startDate: value.startDate, productName: value.productName }))[0];
        return {
          ...contact,
          installationCreatedDate: this.getCreatedDate(contact.createdDate),
          fullAddress: this.getAddress(contact),
          subscription,
        };
      }),

    };
    return mappedData;
  }

  private getAddress(customer: any): string {
    const unformattedAddress = `${customer.street} ${customer.houseNumber || ''} ${customer.houseNumberExtension || ''}, ${customer.zipCode}, ${customer.city}`;
    return this.removeWhitespaces(unformattedAddress);
  }

  private removeWhitespaces(address: string): string {
    return address.replaceAll(' ,', ',');
  }

  async getSubscriptionsByCustomerId(id: string): Promise<SubscriptionResponse[]> {
    const { data } = await this.instance.get<SubscriptionResponse[]>(`v1/subscriptions/${id}`);
    return data;
  }

  private getCreatedDate(date: string): string {
    return date?.substr(0, 10);
  }
}
