import ErrorType from '@/api/dtos/ErrorType';

export default class ProblemDetails {
  type?: string;

  title?: string;

  status?: number;

  detail?: string;

  errors?: Record<ErrorType, string[]>;

  constructor(data: Partial<ProblemDetails>) {
    this.type = data.type;
    this.title = data.title;
    this.status = data.status;
    this.detail = data.detail;
    this.errors = data.errors;
  }

  public static isTypeConsistent(response: any): response is ProblemDetails {
    return 'title' in response && 'status' in response && 'detail' in response && 'errors' in response;
  }
}
